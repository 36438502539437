import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Password } from "primereact/password";
import { isMobile } from "react-device-detect";
import { classNames } from "primereact/utils";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from "primereact/inputtextarea";
import { ErrorMessage } from "@hookform/error-message";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import Permission from "../permission/Permission";
import ImageCrop from "../ImageCrop/ImageCrop";
import { L } from "../../utils/abpUtility";
import { Accordion, AccordionTab } from "primereact/accordion";
import Editor from "../editor/Editor";
import { InputNumber } from "primereact/inputnumber";
import { ImageVisualizer } from "./Helpers/ImageVisualizer";
import { ProgressSpinner } from "primereact/progressspinner";
import ImageUpload from "../imageUpload/ImageUpload";
import { ClubSelection, PartnerClubSelect } from "./crudTableUtils";

let abp = window.abp;

/**
 * It renders a table with a toolbar, a dialog for editing/creating entities, and a dialog for deleting
 * entities
 */
const CrudTable = forwardRef(
  (
    {
      data,
      fieldsToShow,
      loading,
      headerTitle,
      fieldsToEdit,
      fieldsToCreate,
      onEditSubmit,
      setLoading,
      onCreateSubmit,
      onDelete,
      lazy,
      lazyParams,
      setLazyParams,
      globalFilter,
      setGlobalFilter,
      primaryKey,
      getSingleEntity,
      canEdit,
      canCreate,
      canDelete,
      extendBody: ExtendBody,
      extendHeader: ExtendHeader,
      extendDialog: ExtendDialog,
      createPermission,
      editPermission,
      deletePermission,
      defaultValues,
      reorder,
      onRowReorder,
      onSort,
      hideHeader,
      onSelectionChange,
      updateSelectedEntity,
      ...others
    },
    ref
  ) => {
    const [entities, setEntities] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [entityEditDialog, setEntityEditDialog] = useState(false);
    const [managedEntity, setManagedEntity] = useState(defaultValues);
    const [managmentState, setManagmentState] = useState("create");
    const [fields, setFields] = useState([]);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [deleteEntitiesDialog, setDeleteEntitiesDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [activeIndex, setActiveIndex] = useState({});
    const [dialogLoading, setDialogLoading] = useState(false);

    const {
      register: registerEdit,
      handleSubmit: handleEditSubmit,
      reset: resetEditForm,
      watch,
      control,
      setValue,
      getValues,
      formState: { errors },
    } = useForm();

    const toast = useRef(null);
    const dt = useRef(null);

    const refetch = (options) => {
      setDialogLoading(true);
      let keyData = managedEntity[primaryKey];

      if (!keyData) {
        return;
      }

      getSingleEntity(keyData, options).then((res) => {
        if (!res) {
          return;
        }
        setManagedEntity({ ...res });
        setFields(fieldsToEdit);
        setManagmentState("edit");
        setDialogLoading(false);
      });
    };

    useImperativeHandle(ref, () => ({
      refetch,
    }));

    useEffect(() => {
      updateSelectedEntity(managedEntity);
    }, [managedEntity, updateSelectedEntity]);

    useEffect(() => {
      setEntities(data);
    }, [data]);

    useEffect(() => {
      setFields(fieldsToCreate);
    }, [fieldsToCreate]);

    useEffect(() => {
      setFields(fieldsToEdit);
    }, [fieldsToEdit]);

    useEffect(() => {
      resetEditForm(managedEntity);
    }, [managedEntity, resetEditForm]);

    const openNew = () => {
      setManagedEntity(defaultValues);
      setManagmentState("create");
      setFields(fieldsToCreate);
      setEntityEditDialog(true);
    };

    const hideDialog = () => {
      setEntityEditDialog(false);
    };

    const hideDeleteEntityDialog = () => {
      setDeleteEntityDialog(false);
    };

    const hideDeleteEntitiesDialog = () => {
      setDeleteEntitiesDialog(false);
    };

    const editEntity = (ent) => {
      setLoading(true);
      var keyData = ent[primaryKey];

      if (!keyData) {
        return;
      }

      getSingleEntity(ent[primaryKey]).then((res) => {
        if (!res) {
          return;
        }
        setManagedEntity({ ...res });
        setFields(fieldsToEdit);
        setManagmentState("edit");
        setEntityEditDialog(true);
        setLoading(false);
      });
    };

    const confirmDeleteEntity = (ent) => {
      setManagedEntity(ent);
      setDeleteEntityDialog(true);
    };

    const exportCSV = () => {
      dt.current.exportCSV();
    };

    const deleteSelectedProducts = () => {
      let _products = entities.filter((val) => !selectedProducts.includes(val));
      setEntities(_products);
      setDeleteEntitiesDialog(false);
      setSelectedProducts(null);
      toast.current.show({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    };

    const leftToolbarTemplate = () => {
      return (
        <React.Fragment>
          <div className="my-2">
            {canCreate && (
              <Permission permission={createPermission}>
                <Button
                  label={L("New")}
                  icon="pi pi-plus"
                  className="p-button-success mr-2"
                  onClick={openNew}
                />
              </Permission>
            )}
            {/* {canDelete && (
            <Button
              label={L("Delete")}
              icon="pi pi-trash"
              className="p-button-danger"
              onClick={confirmDeleteSelected}
              disabled={!selectedProducts || !selectedProducts.length}
            />
          )} */}
            <ExtendHeader data={data} />
          </div>
        </React.Fragment>
      );
    };

    const rightToolbarTemplate = () => {
      return (
        <React.Fragment>
          {/* <Button
            label={L("Export")}
            icon="pi pi-upload"
            className="p-button-help"
            onClick={exportCSV}
          /> */}
        </React.Fragment>
      );
    };

    const actionBodyTemplate = (rowData) => {
      return (
        <div
          className="actions"
          style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}
        >
          {canEdit && (
            <Permission permission={editPermission}>
              <Button
                icon="pi pi-pencil"
                className="p-button-rounded p-button-success"
                onClick={() => editEntity(rowData)}
              />
            </Permission>
          )}
          {canDelete && (
            <Permission permission={deletePermission}>
              <Button
                icon="pi pi-trash"
                className="p-button-rounded p-button-warning"
                onClick={() => confirmDeleteEntity(rowData)}
              />
            </Permission>
          )}
          <ExtendBody data={rowData} />
        </div>
      );
    };

    const header = (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">{L(headerTitle)}</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder={L("SearchWithThreeDot")}
          />
        </span>
      </div>
    );

    const deleteEntity = () => {
      setLoading(true);
      onDelete(managedEntity);
      setDeleteEntityDialog(false);
    };

    const editDialogFooter = () => (
      <div style={{ width: "fit-content" }}>
        <Button
          label={L("Save")}
          icon="pi pi-check"
          type="submit"
          className="p-button"
        />
      </div>
    );
    const deleteEntityDialogFooter = (
      <>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDeleteEntityDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={deleteEntity}
        />
      </>
    );
    const deleteProductsDialogFooter = (
      <>
        <Button
          label="No"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDeleteEntitiesDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          className="p-button-text"
          onClick={deleteSelectedProducts}
        />
      </>
    );

    const checkIfUserHasProperPermission = (permission) => {
      if (permission !== undefined) {
        return abp.auth.hasPermission(permission);
      }
      return true;
    };

    /**
     * It renders a form field based on the type of field
     * @param type - The type of the field.
     * @param name - The name of the field.
     * @param rules - These are the validation rules for the field.
     * @param props - These are the props that are passed to the component.
     * @returns A function that takes in a type, name, rules, and props.
     */
    const renderEditField = (type, name, rules, permission, props) => {
      switch (type) {
        case "string":
          return (
            <>
              <Controller
                name={name}
                control={control}
                defaultValue={""}
                rules={rules}
                render={({ field, fieldState: { error } }) => (
                  <InputText
                    className={classNames({
                      "p-invalid": error,
                    })}
                    {...field}
                    {...props}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "number":
          return (
            <>
              <Controller
                name={name}
                control={control}
                defaultValue={0}
                rules={{ ...rules }}
                render={({ field }) => (
                  <InputNumber
                    inputId="minmaxfraction"
                    value={field.value}
                    onValueChange={(e) => field.onChange(e.value)}
                    minFractionDigits={2}
                    maxFractionDigits={5}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "dropdown":
          return (
            <>
              <Controller
                name={name}
                control={control}
                rules={{ ...rules }}
                render={({ field }) => (
                  <Dropdown
                    showClear
                    {...props}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.value ? e.value : null);
                    }}
                  />
                )}
              ></Controller>

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "textarea":
          return (
            <>
              <Controller
                name={name}
                control={control}
                defaultValue={""}
                rules={rules}
                render={({ field, fieldState: { error } }) => (
                  <InputTextarea
                    rows={5}
                    className={classNames({
                      "p-invalid": error,
                    })}
                    {...field}
                    {...props}
                  />
                )}
              />

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "bool":
          return (
            <>
              <Controller
                name={name}
                control={control}
                rules={{ ...rules }}
                render={({ field }) => (
                  <InputSwitch {...field} checked={field.value} />
                )}
              ></Controller>

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "password":
          return (
            <>
              <Controller
                name={name}
                rules={{ ...rules }}
                control={control}
                defaultValue=""
                render={({ field }) => <Password {...field} />}
              ></Controller>

              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "imageCrop":
          return !isMobile ? (
            <ImageCrop
              {...props}
              name={name}
              register={registerEdit}
              rules={rules}
              getValue={getValues}
              setValue={setValue}
              ratio={props?.ratio}
              data={managedEntity}
            />
          ) : (
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => (
                <ImageUpload
                  onSelect={(event) => field.onChange(event.files[0])}
                />
              )}
            ></Controller>
          );
        case "image":
          return (
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => (
                <ImageUpload
                  onSelect={(event) => field.onChange(event.files[0])}
                />
              )}
            ></Controller>
          );
        case "images":
          return (
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => (
                <ImageUpload
                  onSelect={(event) => field.onChange(event.files)}
                  multiple={true}
                />
              )}
            ></Controller>
          );
        case "video":
          return (
            <Controller
              name={name}
              control={control}
              rules={rules}
              render={({ field }) => (
                <FileUpload
                  accept="video/*"
                  maxFileSize={10000000000}
                  mode="advanced"
                  onSelect={(event) => field.onChange(event.files[0])}
                />
              )}
            ></Controller>
          );
        case "calendar":
          return (
            <>
              <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={props?.defaultValue}
                render={({ field }) => (
                  <Calendar
                    {...field}
                    value={new Date(field.value)}
                    dateFormat="dd.mm.yy"
                    onChange={(e) =>
                      field.onChange(
                        new Date(
                          e.value.setMinutes(
                            e?.value == null
                              ? 0
                              : -1 * e?.value?.getTimezoneOffset()
                          )
                        ).toISOString()
                      )
                    }
                  />
                )}
              ></Controller>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <p className="error-message">{message ?? null}</p>
                )}
              />
            </>
          );
        case "editor":
        case "partner-club-select":
          return (
            <PartnerClubSelect
              control={control}
              partnerValueName={props?.partnerValueName}
              clubValueName={props?.clubValueName}
              clubOptionValue={props?.clubOptionValue}
              partnerOptionValue={props?.partnerOptionValue}
              watch={watch}
            />
          );
        case "club-select":
          return (
            <ClubSelection
              control={control}
              clubValueName={props?.clubValueName}
              clubOptionValue={props?.clubOptionValue}
            />
          );
        case "json":
          /* Getting the value of the name variable and storing it in the data variable. */
          const data = getValues([name])[0] || {};

          /* Checking if the data object has a screenTitle property. If it does, it assigns the value of
        that property to the nameOverride variable. If it doesn't, it assigns null to the
        nameOverride variable. */
          let nameOverride = data?.screenTitle
            ? data?.screenTitle
            : data?.elementName
            ? data?.elementName
            : data?.title
            ? data?.title
            : name;

          let images = data?.["SdJsonImages"] ?? [];

          const onDeleteButtonClick = (name) => {
            setValue(name, null);

            let freshData = getValues();
            resetEditForm(freshData);
          };

          let imagesToSet = [...new Set(images)];

          return (
            <Accordion
              activeIndex={activeIndex[name]}
              onTabChange={(e) =>
                setActiveIndex({ ...activeIndex, [name]: e.index })
              }
            >
              <AccordionTab header={nameOverride}>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label>Add new property</label>
                  <AddFieldComponent name={name} />

                  <hr />
                  {Object.keys(data).map((key, index) => {
                    let typeName = "";
                    const keyData = data[key];
                    let customName = `${name}.${key}`;

                    let options = { showLabel: true };
                    if (typeof keyData == "object") {
                      typeName = "json";
                      options.showLabel = false;
                    } else {
                      typeName = "string";
                      options.showLabel = true;
                    }
                    let value = getValues([customName])[0];

                    return (
                      value &&
                      key !== "SdJsonImages" && (
                        <div key={index} className="field">
                          {options.showLabel ? (
                            <label htmlFor="name">{L(key)}</label>
                          ) : null}
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {renderEditField(
                              typeName,
                              customName,
                              (rules = {}),
                              (permission = {}),
                              {
                                placeholder: "Enter value for " + key,
                                isObject: typeName === "json" ? true : false,
                              }
                            )}
                            <i
                              className="fa fa-xmark"
                              style={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={() => onDeleteButtonClick(customName)}
                            ></i>
                          </div>
                        </div>
                      )
                    );
                  })}

                  {imagesToSet?.map((i) => (
                    <ImageVisualizer key={i} imageName={i} />
                  ))}
                </div>
              </AccordionTab>
            </Accordion>
          );
        default:
      }
    };

    const AddFieldComponent = ({ name }) => {
      const [inputKey, setInputKey] = useState("");
      const [inputValue, setInputValue] = useState("");
      const [objectKeyInput, setObjectKeyInput] = useState("");
      const [isObject, setIsObject] = useState(false);

      const onSubmit = () => {
        let newKey = `${name}.${inputKey}`;
        setValue(newKey, inputValue);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `Successfully added field with key: ${inputKey}`,
          life: 3000,
        });

        let freshData = getValues();
        resetEditForm(freshData);
      };

      const handleAddObjectButtonClick = () => {
        let newKey = `${name}.${objectKeyInput}`;
        setValue(newKey, {});

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `Successfully added an object with key: ${objectKeyInput}`,
          life: 3000,
        });

        let freshData = getValues();
        resetEditForm(freshData);
      };

      const items = [
        {
          label: "Field",
          icon: "fa fa-paste",
          command: () => {
            setIsObject(false);
          },
        },
        {
          label: "Object",
          icon: "fa fa-diagram-project",
          command: () => {
            setIsObject(true);
          },
        },
      ];

      return (
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <SplitButton
            label="Add"
            model={items}
            style={{ alignSelf: "flex-start", maxWidth: "110px" }}
          />

          {!isObject ? (
            <>
              <InputText
                type="text"
                placeholder="Key"
                value={inputKey}
                onChange={(e) => setInputKey(e.target.value)}
              />

              <InputText
                type="text"
                placeholder="Value"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <Button
                label="Add"
                type="button"
                disabled={!inputKey || !inputValue}
                onClick={() => onSubmit()}
              />
            </>
          ) : (
            <>
              <InputText
                type="text"
                placeholder="Object key"
                value={objectKeyInput}
                onChange={(e) => setObjectKeyInput(e.target.value)}
              />
              <Button
                label="Add Object"
                type="button"
                disabled={!objectKeyInput}
                onClick={() => handleAddObjectButtonClick()}
              />
            </>
          )}
        </div>
      );
    };

    const onEditBaseSubmit = (data) => {
      setLoading(true);
      let submitFnc = managmentState === "edit" ? onEditSubmit : onCreateSubmit;
      submitFnc(data);
      setEntityEditDialog(false);
    };

    const onPage = (event) => {
      setLazyParams({ ...lazyParams, ...event });
    };

    const externalDataTableParams = lazy
      ? {
          first: lazyParams.first,
          page: lazyParams.page,
          rows: lazyParams.rows,
          totalRecords: lazyParams.totalRecords,
          sortField: lazyParams.sortField,
          onPage: onPage,
          ...others,
        }
      : {
          rows: 5,
          first: 0,
          sortField: lazyParams.sortField,
          ...others,
        };

    const getShowFieldBody = (data, field) => {
      switch (field.type) {
        case "image":
          return (
            <img
              src={
                data?.[field.name]?.imageUrl ||
                `data:image/png;base64,${data?.[field.name]?.contents}`
              }
              alt="support your team"
              style={{
                width: "80px",
                borderRadius: "10px",
                height: "70px",
                objectFit: "contain",
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg";
              }}
              lazy={true}
            />
          );
        default:
          return field.body;
      }
    };

    return (
      <div className="grid crud-demo" style={{ width: "100%" }}>
        <div className="col-12">
          <div className="card">
            <Toast ref={toast} />
            {!hideHeader && (
              <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}
              ></Toolbar>
            )}

            <DataTable
              ref={dt}
              loading={loading}
              value={entities}
              selection={selectedProducts}
              onSelectionChange={(e) => {
                setSelectedProducts(e.value);
                onSelectionChange(e.value);
              }}
              dataKey="id"
              paginator
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate={TranslationPaging(
                "{first}",
                "{last}",
                "{totalRecords}"
              )}
              globalFilter={globalFilter}
              emptyMessage={L("No available options")}
              header={header}
              responsiveLayout="scroll"
              lazy={lazy}
              reorderableRows={reorder}
              onRowReorder={onRowReorder}
              expandedRows={expandedRows}
              onSort={(e) => {
                onSort(
                  lazyParams.sortField === e.sortField ? null : e.sortField
                );
              }}
              onRowToggle={(e) => setExpandedRows(e.data)}
              {...externalDataTableParams}
            >
              {reorder && <Column rowReorder></Column>}
              <Column
                selectionMode="single"
                headerStyle={{ width: "3rem" }}
              ></Column>
              {fieldsToShow.map((field) =>
                !field.hidden ? (
                  <Column
                    key={field.name + field.header}
                    field={field.name}
                    header={L(field.header)}
                    body={
                      field.type
                        ? (rowData) => getShowFieldBody(rowData, field)
                        : field.body
                    }
                    sortable={field.sortable || false}
                  />
                ) : null
              )}
              <Column body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog
              visible={entityEditDialog}
              closeOnEscape={false}
              draggable={false}
              style={{ width: "650px" }}
              header={L("Details")}
              modal
              className="p-fluid"
              onHide={hideDialog}
            >
              {dialogLoading ? (
                <ProgressSpinner />
              ) : (
                <form onSubmit={handleEditSubmit(onEditBaseSubmit)}>
                  <ExtendDialog state={managmentState} />
                  {fields
                    .filter((i) => i?.hidden !== true)
                    .map(
                      (field) =>
                        checkIfUserHasProperPermission(field.permission) !==
                          false && (
                          <div
                            className="field"
                            key={field.name + field.type + field.title}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label htmlFor="name">{L(field.title)}</label>

                            {field.body ? (
                              <>
                                {field.body({
                                  register: registerEdit,
                                  control,
                                  name: field.name,
                                  errors,
                                  getValues: getValues,
                                  watch: watch,
                                  reset: resetEditForm,
                                  rules: field.rules,
                                  data: managedEntity,
                                })}
                                <ErrorMessage
                                  errors={errors}
                                  name={field.name}
                                  render={({ message }) => (
                                    <p className="error-message">
                                      {message ?? null}
                                    </p>
                                  )}
                                />
                              </>
                            ) : (
                              renderEditField(
                                field.type,
                                field.name,
                                field.rules,
                                field.permission,
                                field.props
                              )
                            )}
                          </div>
                        )
                    )}
                  {editDialogFooter()}
                </form>
              )}
            </Dialog>

            <Dialog
              visible={deleteEntityDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteEntityDialogFooter}
              onHide={hideDeleteEntityDialog}
            >
              <div className="flex align-items-center justify-content-center">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {managedEntity && (
                  <span>Are you sure you want to delete this entity?</span>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteEntitiesDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductsDialogFooter}
              onHide={hideDeleteEntitiesDialog}
            >
              <div className="flex align-items-center justify-content-center">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {selectedProducts && (
                  <span>
                    Are you sure you want to delete the selected products?
                  </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
);

export function TranslationPaging(first, last, totalRecords) {
  return (
    L("ShowingResult") +
    " " +
    first +
    " " +
    L("ShowingResultTo") +
    " " +
    last +
    " " +
    L("ShowingResultFrom") +
    " " +
    totalRecords +
    " " +
    L("ShowingResultElements")
  );
}

CrudTable.defaultProps = {
  data: [],
  fieldsToShow: [],
  fieldsToCreate: [],
  fieldsToEdit: [],
  loading: true,
  headerTitle: "Manage Entities",
  onEditSubmit: () => {},
  onCreateSubmit: () => {},
  onDelete: () => {},
  setLoading: () => {},
  lazy: false,
  setLazyParams: () => {},
  lazyParams: {
    rows: 5,
    first: 0,
    page: 1,
  },
  globalFilter: "",
  setGlobalFilter: () => {},
  primaryKey: "id",
  getSingleEntity: () => {
    return {};
  },
  canEdit: true,
  canDelete: true,
  canCreate: true,
  extendBody: () => null,
  extendHeader: () => null,
  extendDialog: () => null,
  createPermission: "",
  editPermission: "",
  deletePermission: "",
  defaultValues: {},
  reorder: false,
  onRowReorder: () => {},
  onSort: (field) => {},
  hideHeader: false,
  updateSelectedEntity: () => {},
};

CrudTable.propTypes = {};

export default CrudTable;
