import http, { getFormData } from "../http/httpService";

class CampaignsService {
  async get(entityDto) {
    let result = await http.get("api/services/app/Campaigns/GetById", {
      params: entityDto,
    });
    return result.data.result;
  }

  async update(updateCampaignInput) {
    updateCampaignInput.clubId = updateCampaignInput.club.id;
    updateCampaignInput.referralLandingPageJson = JSON.stringify(
      updateCampaignInput.referralLandingPageJson
    );
    updateCampaignInput.layoutJson = JSON.stringify(
      updateCampaignInput.layoutJson
    );

    updateCampaignInput.explainerVideoJson = JSON.stringify(
      updateCampaignInput.explainerVideoJson
    );

    updateCampaignInput.explainerScreenJson = JSON.stringify(
      updateCampaignInput.explainerScreenJson
    );

    const formData = getFormData(updateCampaignInput);

    let result = await http.put("api/services/app/Campaigns/Update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return result.data.result;
  }

  async create(createCampaignInput) {
    const formData = getFormData(createCampaignInput);

    let result = await http.post(
      "api/services/app/Campaigns/Create",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data.result;
  }

  async delete(entityDto) {
    let result = await http.delete("api/services/app/Campaigns/DeleteById", {
      params: entityDto,
    });
    return result.data;
  }

  async getAll(pagedFilterAndSortedRequest) {
    let result = await http.get("api/services/app/Campaigns/GetAll", {
      params: pagedFilterAndSortedRequest,
    });
    return result.data.result;
  }
}

export default new CampaignsService();
