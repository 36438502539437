import React, { useEffect, useRef, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import cardTypesService from "../../services/cardTypes/cardTypesService";
import clubService from "../../services/club/clubService";
import { useErrors } from "../../utils/error/useErrors";
import { fieldsToShow, fieldsToEdit, fieldsToCreate } from "./cardTypesConfig";
import { Dropdown } from "primereact/dropdown";
import { LanguageSelect } from "../../components/crudTable/crudTableUtils";
import { useQuery } from "@tanstack/react-query";

let previousLang = null;

const CardTypes = () => {
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 5,
    page: 1,
    sortField: null,
    sortOrder: null,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedClub, setSelectedClub] = useState({});
  const [loading, setLoading] = useState(false);

  const { data: clubData } = useQuery({
    queryKey: [
      "clubs",
      {
        hasNoImages: true,
        hasNoPaginator: true,
      },
    ],
    queryFn: () =>
      clubService.getAll({ hasNoImages: true, hasNoPaginator: true }),
  });

  let clubs = clubData?.items || [];

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "cardTypes",
      lazyParams.first,
      lazyParams.rows,
      globalFilter,
      selectedClub,
    ],
    queryFn: () =>
      cardTypesService.getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
        ClubId: selectedClub,
      }),
  });

  let cardTypes = data?.items;
  const totalRecords = data?.totalCount;

  const [fieldsCreate, setFieldsCreate] = useState(fieldsToCreate);
  const { checkErrors } = useErrors();
  const [lang, setLang] = useState(1);
  const ref = useRef();
  let timer;

  useEffect(() => {
    if (lang !== previousLang && previousLang !== null) {
      ref.current.refetch({ lang });
    }
  }, [lang]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const onEditSubmit = (data) => {
    setLoading(true);
    cardTypesService
      .update(data, lang)
      .then(() => {
        refetch();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    setLoading(true);
    cardTypesService
      .create(data)
      .then((res) => {
        refetch();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    setLoading(true);
    cardTypesService
      .delete({
        id: data.id,
      })
      .then(() => {
        refetch();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onClubChange = (value) => {
    setSelectedClub(value);
  };

  const extendHeader = () => {
    return (
      !window.club && (
        <Dropdown
          className="ml-3"
          value={selectedClub}
          onChange={(e) => onClubChange(e.target.value)}
          options={clubs}
          optionValue="id"
          optionLabel="clubName"
          placeholder="Select a club..."
          showClear
        />
      )
    );
  };

  const getSingleCardType = async (id, options) => {
    setLoading(true);
    const cardTypeData = await cardTypesService.get({
      id,
      ...options,
      lang: options?.lang || lang,
    });
    setLoading(false);
    cardTypeData.clubId = cardTypeData.club?.id;
    return cardTypeData;
  };

  const ExtendDialog = ({ state }) => {
    return (
      state === "edit" && (
        <div className="pb-3">
          <h4>Select Language:</h4>
          <LanguageSelect
            value={lang}
            onChange={(e) => {
              previousLang = lang;
              setLang(e.target.value);
            }}
          />
        </div>
      )
    );
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={cardTypes}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsCreate}
        loading={isLoading || loading}
        headerTitle="Manage Card Types"
        setLoading={() => {}}
        lazy={true}
        lazyParams={{ ...lazyParams, totalRecords: totalRecords }}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        extendHeader={extendHeader}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleCardType}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        deletePermission={"Pages.CardTypes.Delete"}
        createPermission={"Pages.CardTypes.Create"}
        editPermission={"Pages.CardTypes.Edit"}
        extendDialog={ExtendDialog}
        ref={ref}
      />
    </div>
  );
};

export default CardTypes;
