import React, { useEffect, useState } from "react";
import CrudTable from "../../components/crudTable/CrudTable";
import campaignsService from "../../services/campaigns/campaignsService";
import clubsService from "../../services/club/clubService";
import { useErrors } from "../../utils/error/useErrors";
import {
  fieldsToShow,
  fieldsToEdit,
  fieldsToCreate,
  defaultValues,
} from "./campaignsConfig";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkErrors } = useErrors();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 25,
    page: 1,
    sortField: null,
    sortOrder: null,
    totalRecords: 0,
  });
  const [globalFilter, setGlobalFilter] = useState("");
  let timer;

  useEffect(() => {
    getAllCampaigns();
  }, [lazyParams.first, lazyParams.rows, globalFilter]);

  const onGlobalFilterChange = (value) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      setGlobalFilter(value);
    }, 300);
  };

  const onEditSubmit = (data) => {
    campaignsService
      .update(data)
      .then(() => {
        getAllCampaigns();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getAllCampaigns = () => {
    setLoading(true);
    campaignsService
      .getAll({
        SkipCount: lazyParams.first,
        MaxResultCount: lazyParams.rows,
        Keyword: globalFilter,
      })
      .then((campaigns) => {
        setCampaigns(campaigns.items);
        setLazyParams({ ...lazyParams, totalRecords: campaigns.totalCount });
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onCreateSubmit = (data) => {
    campaignsService
      .create(data)
      .then((res) => {
        getAllCampaigns();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const onDelete = (data) => {
    campaignsService
      .delete({
        id: data.id,
      })
      .then(() => {
        getAllCampaigns();
        setLoading(false);
      })
      .catch((e) => {
        checkErrors(e);
        setLoading(false);
      });
  };

  const getSingleCampaign = async (id) => {
    const campaignData = await campaignsService.get({ id });

    campaignData.clubId = campaignData.club?.id;

    campaignData.layoutJson = JSON.parse(campaignData.layoutJson);

    campaignData.explainerScreenJson = JSON.parse(
      campaignData.explainerScreenJson
    );
    campaignData.shareToWinScreenJson = JSON.parse(
      campaignData.shareToWinScreenJson
    );
    campaignData.referFriendsFromContactsJson = JSON.parse(
      campaignData.referFriendsFromContactsJson
    );
    campaignData.referralLandingPageJson = JSON.parse(
      campaignData.referralLandingPageJson
    );
    return campaignData;
  };

  return (
    <div>
      <CrudTable
        primaryKey="id"
        data={campaigns}
        fieldsToShow={fieldsToShow}
        fieldsToEdit={fieldsToEdit}
        fieldsToCreate={fieldsToCreate}
        loading={loading}
        headerTitle="Manage Campaigns"
        setLoading={setLoading}
        lazy={true}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        globalFilter={globalFilter}
        setGlobalFilter={onGlobalFilterChange}
        getSingleEntity={getSingleCampaign}
        onEditSubmit={onEditSubmit}
        onCreateSubmit={onCreateSubmit}
        onDelete={onDelete}
        deletePermission={"Pages.Campaign.Delete"}
        createPermission={"Pages.Campaign.Create"}
        editPermission={"Pages.Campaign.Edit"}
        defaultValues={defaultValues}
        canCreate={false}
      />
    </div>
  );
};

export default Campaigns;
